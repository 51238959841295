<template>
  <div class="pt-6 pb-20 lg:pb-24">
    <h3 v-if="title"
        class="max-w-3xl text-2xl lg:text-4xl leading-[1.2] text-white tracking-[-1px] mb-6 lg:mb-14"
    >
      {{ title }}
    </h3>
    <NuxtLink v-for="(job, index) in jobsSortedByPriority"
              :key="index"
              class="flex flex-col items-start justify-between pt-6 pb-6 transition border-b border-gray-400 lg:px-4 lg:hover:bg-gray-500 lg:flex-row lg:pt-8 lg:items-center group"
              :href="resolveJobUri(job.uri)"
    >
      <div class="">
        <div class="text-xl text-gray-100 transition lg:text-2xl lg:text-gray-200 lg:group-hover:text-white">
          {{ job.title }}
        </div>
        <div class="">
          <span v-for="(location, i) in job.locations"
                :key="location.key"
                class="mt-1 text-gray-250"
          >{{ i
            > 0 ? ' • ' : '' }}{{ location.label }}
          </span>
        </div>
      </div>

      <div class="flex flex-wrap items-start mt-4 gap-2 lg:ml-auto lg:mt-0">
        <div v-if="job.area"
             class="px-4 py-1 text-sm bg-gray-200 border border-gray-200 rounded-full"
        >
          {{ job.area }}
        </div>
        <div v-if="job.employmentType"
             class="px-4 py-1 text-sm text-gray-200 border border-gray-200 rounded-full"
        >
          {{ job.employmentType }}
        </div>
        <template v-if="job.workTypes">
          <div v-for="workType in job.workTypes"
               :key="workType"
               class="px-4 py-1 text-sm text-gray-200 border border-gray-200 rounded-full"
          >
            {{ workType }}
          </div>
        </template>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { JobType } from '~/models/jobs'

interface TableProps {
  title?: string
  jobs: JobType[]
}

const props = withDefaults(defineProps<TableProps>(), {
  title: ''
})

const { isInternalJobReferrals } = useInternalJobreferrals()

const jobsSortedByPriority = computed(() => {
  const jobs = [...props.jobs]

  jobs.forEach(job => {
    if (!job.priority) job.priority = 3
  })

  return jobs.sort((a, b) => a.priority - b.priority)
})

function resolveJobUri(jobUri: string) {
  return isInternalJobReferrals.value ? jobUri.replace('/jobs/', '/internal-job-referrals/') : jobUri
}
</script>
